<i18n src="./lang.json" />
<template>
  <v-card id="csNotificationHistoryContainer">
    <v-card-title class="pa-0">
      <v-toolbar
        class="csGradientBlueBackground fullscreenDialogToolbar mb-3 pa-0"
        flat
      >
        <v-toolbar-title class="pa-0 fullscreenDialogToolbarTitle">
          <v-list-item-avatar
            class="notificationHistoryAvatar"
            color="white"
            size="60px"
          >
            <v-icon size="24">{{ $csBasicsIcons.messageExclamation }}</v-icon>
          </v-list-item-avatar>
          <span style="color: white">{{ $t('notificationHistory') }}</span>
          <v-spacer />
          <cs-action-button
            :action="() => $emit('notificationHistoryClosed')"
            class="mr-1"
            :icon="$csBasicsIcons.closeFontawesome"
            icon-color="white"
            icon-size="22"
            :tooltip="$t('close')"
          />
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <div
        v-if="!notificationHistory.length > 0"
        style="display: flex; align-items: center; margin-top: 20px"
      >
        <cs-icon-circle :icon="$csBasicsIcons.informationNew" />
        <span class="ml-3 csSubHeading">{{ $t('noRecentNotifications') }}</span>
      </div>
      <div v-else-if="notificationHistory.length > 0">
        <div style="display: flex; align-items: center">
          <span
            v-if="!selectedNotificationType && !oldestFirst"
            class="csSubHeading mb-2"
            >{{ $t('allNotifications') }}
          </span>
          <div v-else>
            <v-chip
              v-if="showError"
              class="chip"
              close
              outlined
              @click:close="showError = false"
              >{{ $t('error') }}
            </v-chip>
            <v-chip
              v-if="showInfo"
              class="chip"
              close
              outlined
              @click:close="showInfo = false"
              >{{ $t('info') }}
            </v-chip>
            <v-chip
              v-if="showSuccess"
              class="chip"
              close
              outlined
              @click:close="showSuccess = false"
              >{{ $t('success') }}
            </v-chip>
            <v-chip
              v-if="showWarn"
              class="chip"
              close
              outlined
              @click:close="showWarn = false"
              >{{ $t('warn') }}
            </v-chip>
            <v-chip
              v-if="oldestFirst"
              class="chip"
              close
              outlined
              @click:close="oldestFirst = false"
              >{{ $t('oldestFirst') }}
            </v-chip>
          </div>
          <v-spacer />
          <v-menu
            v-model="showFilterMenu"
            :close-on-content-click="false"
            offset-x
            right
            rounded
          >
            <template #activator="{ on }">
              <cs-action-button
                class="mb-2 mr-1"
                :icon="$csBasicsIcons.filter"
                :icon-color="
                  selectedNotificationType || oldestFirst
                    ? $csBasicsColors.csPrimaryDarkBlue
                    : $csBasicsColors.csDark
                "
                icon-size="24"
                move-tooltip-by="10"
                :tooltip="$t('filterNotifications')"
                v-on="on"
                @actionButtonClicked="showFilterMenu = true"
              />
            </template>
            <div
              class="filter pa-0"
              style="
                width: 350px;
                background-color: white;
                display: flex;
                flex-direction: column;
              "
            >
              <div
                class="csGradientBlueBackground"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 16px;
                "
              >
                <v-subheader
                  class="pa-0 csSubHeading widget-subheader"
                  style="color: #f8f8f8"
                  >{{ $t('filterNotifications') }}
                </v-subheader>
                <div style="display: flex; align-items: center">
                  <cs-action-button
                    :icon="$csBasicsIcons.closeMaterial"
                    :icon-color="$csBasicsColors.csLight"
                    icon-size="22"
                    :tooltip="$t('close')"
                    @actionButtonClicked="showFilterMenu = false"
                  />
                </div>
              </div>
              <div
                style="display: flex; flex-direction: column; padding: 16px"
                :style="[
                  selectedNotificationType || oldestFirst
                    ? { paddingTop: '6px' }
                    : { paddingTop: '16px' }
                ]"
              >
                <div style="display: flex; justify-content: center">
                  <v-btn
                    v-if="selectedNotificationType || oldestFirst"
                    block
                    :color="$csBasicsColors.csPrimaryDarkBlue"
                    style="text-transform: capitalize; min-width: 100%"
                    text
                    @click="resetFilter"
                  >
                    {{ $t('resetTooltip') }}
                  </v-btn>
                </div>
                <v-list-item class="pa-0">
                  <div
                    style="display: flex; flex-direction: column; width: 100%"
                  >
                    <span class="csSubHeading csMediumText mb-1"
                      >{{ $t('selectNotificationType') }}:</span
                    >
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <v-switch
                          v-model="showError"
                          class="switch ma-0 mb-2 mr-2"
                          hide-details
                          :label="$t('error')"
                        />
                        <v-switch
                          v-model="showWarn"
                          class="switch ma-0 mb-2 mr-2"
                          hide-details
                          :label="$t('warn')"
                        />
                      </div>
                      <div>
                        <v-switch
                          v-model="showInfo"
                          class="switch ma-0 mb-2 mr-2"
                          hide-details
                          :label="$t('info')"
                        />
                        <v-switch
                          v-model="showSuccess"
                          class="switch ma-0 mb-2 mr-2"
                          hide-details
                          :label="$t('success')"
                        />
                      </div>
                    </div>
                  </div>
                </v-list-item>
                <v-list-item class="pa-0">
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: start;
                    "
                  >
                    <span class="csSubHeading csMediumText mt-2 mb-1"
                      >{{ $t('setOrder') }}:</span
                    >
                    <v-switch
                      v-model="oldestFirst"
                      class="switch ma-0 mr-2"
                      hide-details
                      :label="$t('oldestFirst')"
                    />
                  </div>
                </v-list-item>
              </div>
            </div>
          </v-menu>
        </div>
        <v-divider style="margin-bottom: 20px" />
        <div
          v-if="notificationHistory.length > 0 && !notifications.length > 0"
          style="display: flex; align-items: center"
        >
          <cs-icon-circle :icon="$csBasicsIcons.informationNew" />
          <span class="ml-3 csSubHeading">{{ $t('noResultsForFilter') }}</span>
        </div>
        <cs-perfect-scrollbar v-else height="74vh">
          <template #perfectScrollbarContent>
            <div id="csNotificationsMessagesWrapper">
              <div v-for="(notification, index) in notifications" :key="index">
                <v-hover v-slot="{ hover }" open-delay="150">
                  <div style="display: flex; align-items: center">
                    <cs-inner-widget-card class="pt-0 pl-0" style="width: 100%">
                      <template #csInnerWidgetCardMainContent>
                        <div class="notificationContainer">
                          <div>
                            <v-icon
                              :color="notificationIconColor(notification)"
                              style="width: 50px"
                              >{{ notificationIcon(notification) }}
                            </v-icon>
                          </div>
                          <div style="display: flex; flex-direction: column">
                            <span class="csSubHeading">{{
                              notification.title
                            }}</span>
                            <span class="csText csLightText">{{
                              notification.message
                            }}</span>
                            <span class="csText csLightText"
                              >{{
                                $csBasicsFormat.formatDate(
                                  notification.timestamp
                                )
                              }}
                              {{ $t('at') }}
                              {{
                                $csBasicsFormat.formatTime(
                                  notification.timestamp
                                )
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </cs-inner-widget-card>
                    <cs-action-button
                      class="removeNotificationButton"
                      :class="{ 'on-hover': hover }"
                      :icon="$csBasicsIcons.deleteMaterial"
                      :icon-color="$csBasicsColors.csDark"
                      icon-size="22"
                      :tooltip="$t('remove')"
                      @actionButtonClicked="removeNotification(notification.id)"
                    />
                  </div>
                </v-hover>
              </div>
            </div>
          </template>
        </cs-perfect-scrollbar>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'CSBaseNotificationHistory',
  data() {
    return {
      showError: false,
      showWarn: false,
      showInfo: false,
      showSuccess: false,
      oldestFirst: false,
      showFilterMenu: false,
      notificationHistory: []
    }
  },
  computed: {
    /**
     * Checks if a filter for a notification type is selected
     */
    selectedNotificationType() {
      return (
        this.showError || this.showSuccess || this.showInfo || this.showWarn
      )
    },
    /**
     * Returns filtered + sorted notifications for the UI
     * @returns {*[]}
     */
    notifications() {
      return this.filterAndSortNotifications(this.notificationHistory)
    }
  },
  mounted() {
    this.notificationHistory = this.getNotificationHistory()
  },
  methods: {
    /**
     * Method to return the notification history
     */
    getNotificationHistory() {
      return this.$csNotificationHistory.getNotificationHistory()
    },
    /**
     * Removes a notification from the notification history
     */
    removeNotification(id) {
      this.$csNotificationHistory.removeNotification(id)
    },
    /**
     * Resets all filter parameters to their default
     */
    resetFilter() {
      this.showError = false
      this.showWarn = false
      this.showSuccess = false
      this.showInfo = false
      this.oldestFirst = false
    },
    /**
     * Filters notification by selected type and orders them ascending or descending in regards to their creation timestamp
     */
    filterAndSortNotifications(notifications) {
      let filteredNotifications = []
      notifications.forEach((notification) => {
        if (this.selectedNotificationType) {
          if (this.showWarn) {
            if (notification.type === 'warn') {
              filteredNotifications.push(notification)
            }
          }
          if (this.showInfo) {
            if (notification.type === 'info') {
              filteredNotifications.push(notification)
            }
          }
          if (this.showError) {
            if (notification.type === 'error') {
              filteredNotifications.push(notification)
            }
          }
          if (this.showSuccess) {
            if (notification.type === 'success') {
              filteredNotifications.push(notification)
            }
          }
        } else {
          filteredNotifications.push(notification)
        }
      })
      filteredNotifications.sort((a, b) => {
        if (!this.oldestFirst) {
          return b.timestamp - a.timestamp
        } else if (this.oldestFirst) {
          return a.timestamp - b.timestamp
        }
      })
      return filteredNotifications
    },
    /**
     * Returns icon color depending on notification type
     * @param notification
     * @returns {string}
     */
    notificationIconColor(notification) {
      let color
      if (notification.type === 'info') {
        color = this.$csBasicsColors.csPrimaryDarkBlue
      } else if (notification.type === 'warn') {
        color = this.$csBasicsColors.csAccentOrange
      } else if (notification.type === 'error') {
        color = this.$csBasicsColors.csAccentRed
      } else if (notification.type === 'success') {
        color = this.$csBasicsColors.csAccentGreen
      }
      return color
    },
    /**
     * Returns icon depending on notificationType
     * @param notification
     * @returns {string}
     */
    notificationIcon(notification) {
      let icon
      if (notification.type === 'info') {
        icon = this.$csBasicsIcons.informationNew
      } else if (notification.type === 'warn') {
        icon = this.$csBasicsIcons.warningNew
      } else if (notification.type === 'error') {
        icon = this.$csBasicsIcons.errorNew
      } else if (notification.type === 'success') {
        icon = this.$csBasicsIcons.successNew
      }
      return icon
    }
  }
}
</script>

<style lang="scss" scoped>
#csNotificationHistoryContainer {
  .fullscreenDialogToolbar {
    padding-top: 7.5%;
    padding-bottom: 5%;
    position: sticky !important;
    top: 0;
    z-index: 999;
    height: 100px !important;

    .fullscreenDialogToolbarTitle {
      color: $cs-dark;
      padding-top: 80px;
      font-weight: normal;
      font-size: 24px;
      width: 100%;
      display: flex;
      align-items: center;

      .notificationHistoryAvatar i {
        font-size: 5rem;
        font-style: normal;
        background: rgba(0, 0, 0, 0)
          linear-gradient(to left bottom, rgb(3, 152, 226), rgb(2, 67, 100))
          repeat scroll 0 0;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-animation: fontgradient 2s ease infinite;
        -moz-animation: fontgradient 2s ease infinite;
      }
    }
  }
  .removeNotificationButton {
    transition: opacity 0.4s ease-in-out;
  }
  .removeNotificationButton:not(.on-hover) {
    opacity: 0;
  }
  .chip {
    color: $cs-primary-dark-blue;
    margin: 0 5px 10px 5px;
    border-color: $cs-primary-dark-blue;
  }

  #csNotificationsMessagesWrapper > div:nth-child(even) {
    .csInnerWidgetCard {
      border-top: none;
      border-bottom: none;
    }
  }
  #csNotificationsMessagesWrapper > div:first-child {
    .csInnerWidgetCard {
      border-top: thin solid rgba(0, 0, 0, 0.12);
    }
  }
  #csNotificationsMessagesWrapper > div:last-child {
    .csInnerWidgetCard {
      border-bottom: thin solid rgba(0, 0, 0, 0.12);
    }
  }
  .notificationContainer {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
</style>
<style lang="scss">
.chip .v-chip__close {
  color: $cs-primary-dark-blue !important;
}
.switch .v-label {
  color: $cs-dark !important;
}
.filter .v-input--is-label-active .v-input--switch__track {
  color: $cs-primary-dark-blue !important;
}
.filter .v-input--switch__track {
  color: $cs-dark !important;
}
.filter .v-input--is-label-active .v-input--switch__thumb {
  color: $cs-primary-dark-blue !important;
}
.filter .v-input--switch__thumb {
  color: $cs-background !important;
}
</style>
