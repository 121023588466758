/**
 * Helper class for permissions
 */
import { Auth } from '@aws-amplify/auth'
import store from '@/store'
import { BASE_CLIENT } from '@/main'
import GraphqlHelper from '@/js/graphqlHelper'

export default {
  /**
   * Verify access permissions for the service
   *
   * @param serviceKey the service key
   * @param serviceRights the list of service rights (if empty, it will be taken from the current user's session)
   * @return {boolean} true if user has access permission, false otherwise
   */
  async hasServiceAccess(serviceKey, serviceRights) {
    let hasAccess = false

    if (!serviceKey) {
      return false
    }

    if (!serviceRights) {
      let currentSession = await Auth.currentSession()
      serviceRights = currentSession.getIdToken().payload['service_rights']
    }

    if (typeof serviceRights === 'string' && serviceRights.trim()) {
      let serviceRightsList = serviceRights.split(',')
      if (
        serviceRightsList.includes('ALL') ||
        serviceRightsList.includes(serviceKey.toUpperCase())
      ) {
        hasAccess = true
      }
    }

    return hasAccess
  },

  /**
   * Verify access permissions for the sub-service
   *
   * @param serviceKey the service key
   * @param subServiceKey the sub-service key
   * @param serviceRights the list of service rights (if empty, it will be taken from the current user's session)
   * @param subServiceRights the list of sub-service rights (if empty, it will be taken from the current user's
   *     session)
   * @return {boolean} true if user has access permission, false otherwise
   */
  async hasSubServiceAccess(
    serviceKey,
    subServiceKey,
    serviceRights,
    subServiceRights
  ) {
    let hasAccess = false

    if (!serviceKey || !subServiceKey) {
      return false
    }
    /** NO PERMISSION CHECK for ResearchHosting currently TODO */
    if (serviceKey === 'CSResearchHosting') {
      await BASE_CLIENT.query({ query: GraphqlHelper.getDeployedServices() })
        .then(async (response) => {
          let deployedServices = []
          response.data.getDeployedServices.forEach((deployedService) => {
            deployedServices.push(deployedService.service)
          })
          if (
            deployedServices &&
            deployedServices.length > 0 &&
            deployedServices.find((el) => el === 'CSRESEARCHHOSTING')
          ) {
            let authUser = await store.dispatch(
              'csbaseAuthModule/getAuthenticatedUser'
            )
            if (authUser) {
              if (subServiceKey === 'CSRH_CustomerManagement') {
                let userGroups = await store.dispatch(
                  'csbaseAuthModule/getCurrentSessionUserGroups'
                )
                if (
                  userGroups &&
                  userGroups.length > 0 &&
                  userGroups.find(
                    (userGroup) =>
                      userGroup === 'SYSTEM' || userGroup === 'ADMINISTRATORS'
                  )
                ) {
                  hasAccess = true
                }
              } else {
                hasAccess = true
              }
            }
          }
        })
        .catch(() => {})
      return hasAccess
    }
    if (!serviceRights || !subServiceRights) {
      let currentSession = await Auth.currentSession()
      serviceRights = currentSession.getIdToken().payload['service_rights']
      subServiceRights =
        currentSession.getIdToken().payload['sub_service_rights']
    }

    if (
      typeof serviceRights === 'string' &&
      serviceRights.trim() &&
      typeof subServiceRights === 'string' &&
      subServiceRights.trim()
    ) {
      let serviceRightsList = serviceRights.split(',')
      if (
        serviceRightsList.includes('ALL') ||
        serviceRightsList.includes(serviceKey.toUpperCase())
      ) {
        let subServiceRightsList = subServiceRights.split(',')
        if (
          subServiceRightsList.includes('ALL') ||
          subServiceRightsList.includes(serviceKey.toUpperCase() + '_ALL') ||
          subServiceRightsList.includes(subServiceKey.toUpperCase())
        ) {
          hasAccess = true
        }
      }
    }

    return hasAccess
  },

  /**
   * This method checks if the user is allowed to perform a certain restricted action.
   * @param restrictedAction Restricted action with fields that need to be included in the permissions object
   * of the current authenticated user.
   * @param store The VueX Store.
   * @returns {boolean} Permission granted (or not) to execute a restricted action.
   */
  userHasExecutionPermission(restrictedAction, store) {
    const isMatchingString = function (string1, string2) {
      return string1.toLocaleLowerCase() === string2.toLocaleLowerCase()
    }
    const findPermission = function (userPermission) {
      return userPermission.permissions.find((permission) =>
        isMatchingString(permission, restrictedAction.permission)
      )
    }
    let userHasExecutionPermission = false
    let userPermissions = store.getters['csbaseAuthModule/userPermissions']
    if (userPermissions) {
      userHasExecutionPermission = userPermissions.find((userPermission) => {
        let hasPermission = false
        //CHECK IF USER HAS ADMIN ACCESS FIRST
        if (
          isMatchingString(userPermission.resourceType, 'ADMINISTRATOR_ACCESS')
        ) {
          return !hasPermission
        } else {
          //CHECK RESOURCE_TYPE
          if (
            restrictedAction.resourceType &&
            userPermission.resourceType &&
            isMatchingString(
              userPermission.resourceType,
              restrictedAction.resourceType
            )
          ) {
            //CHECK RESOURCE_ID
            if (restrictedAction.resourceId) {
              if (
                restrictedAction.resourceId &&
                userPermission.resourceId &&
                isMatchingString(
                  userPermission.resourceId,
                  restrictedAction.resourceId
                )
              ) {
                //CHECK PERMISSION
                if (restrictedAction.permission && userPermission.permissions) {
                  hasPermission = findPermission(userPermission)
                }
              }
            } else {
              //CHECK PERMISSION
              if (restrictedAction.permission && userPermission.permissions) {
                hasPermission = findPermission(userPermission)
              }
            }
          }
        }
        return !!hasPermission
      })
    }
    return !!userHasExecutionPermission
  }
}
