import { render, staticRenderFns } from "./EditRole.vue?vue&type=template&id=56017b3c&scoped=true"
import script from "./EditRole.vue?vue&type=script&lang=js"
export * from "./EditRole.vue?vue&type=script&lang=js"
import style0 from "./EditRole.vue?vue&type=style&index=0&id=56017b3c&prod&lang=scss"
import style1 from "./EditRole.vue?vue&type=style&index=1&id=56017b3c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56017b3c",
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Fcsbaseapp-frontend%2Fcsbaseapp-frontend%2Fsrc%2Fviews%2Fcsbase-role-management%2FEditRole.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VIcon,VList,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VOverlay,VTextField})
