import { render, staticRenderFns } from "./AlertHistoryAddMenu.vue?vue&type=template&id=80cc1860&scoped=true"
import script from "./AlertHistoryAddMenu.vue?vue&type=script&lang=js"
export * from "./AlertHistoryAddMenu.vue?vue&type=script&lang=js"
import style0 from "./AlertHistoryAddMenu.vue?vue&type=style&index=0&id=80cc1860&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80cc1860",
  null
  
)

/* custom blocks */
import block0 from "./lang.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Fcsbaseapp-frontend%2Fcsbaseapp-frontend%2Fsrc%2Fcomponents%2Fcsbase-alerts%2Fcsbase-alert-content%2Fcsbase-alert-history%2FAlertHistoryAddMenu.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VBtn,VIcon,VTextField,VTimelineItem})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
